import React, { useState } from 'react';
import { BasicUser, RequestProps } from '@/types/service-invoke';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'; // Importing the exclamation icon
import Pricing from '../Pricing';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid';

interface DataInputFormProps {
  formData: RequestProps;
  // @ts-ignore
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSubmit: () => void;
  isDownloadReady: boolean;
  submitted: boolean;
  page?: "landing" | "dashboard";
  showPricing?: boolean;
  setShowPricing?: (show: boolean) => void;
  remainingCredit?: number;
  servicePlanId?: string;
  user?: BasicUser
}

const DataInputForm: React.FC<DataInputFormProps> = ({
  formData, handleChange, handleSubmit, isDownloadReady, submitted, page = "dashboard",
  showPricing = false, setShowPricing = () => { }, remainingCredit = 0, servicePlanId = null,
  user = null,
}) => {
  const [errors, setErrors] = useState({ nodeDescription: '' });
  const [lockOpen, setLockOpen] = useState(false);

  const heightCss = (page === "landing") ? "h-24" : "h-40";

  const validateAndSubmit = () => {
    let newErrors = { nodeDescription: '' };

    if (formData.nodeDescription.length <= 10) {
      newErrors.nodeDescription = 'Your description seems a bit short. Could you provide a bit more detail? We recommend at least 10 characters for the best results.';
    } else {
      newErrors.nodeDescription = '';
    }

    setErrors(newErrors);
    if (newErrors.nodeDescription) {
      return;
    }
    handleSubmit();
  };

  return (
    <div className="form-control w-full space-y-4 mt-0">
      {showPricing && <Pricing title={remainingCredit < 1 ? "Please Get more Credits below" : ""} user={user} />}
      <fieldset className="input-group flex-col lg:p-8 lg:pb-0 pb-0 p-4 border border-gray-700 rounded-lg alert">

        {((remainingCredit === 0) || (servicePlanId == null)) && (<div
          onClick={() => setShowPricing?.(!showPricing)}
          onMouseEnter={() => setLockOpen(true)}
          onMouseLeave={() => setLockOpen(false)}
          className="sm:flex-row"
        >
          <div className="alert pt-0 flex justify-between items-center flex-col sm:flex-row md:flex-row">
            <div className="text-center sm:text-left mb-4 sm:mb-0">
              <div className={`bg-transparent ${page === "dashboard" && "cursor-pointer"} `}>
                <div>GPT-3.5 used for trial, GPT-4 used for paid customers</div>
                <div className="mt-2">GTP-4 Writes
                  <span className="transform inline-block ml-2 -mr-2 p-2 bg-green-300 rounded-md text-black font-bold" style={{ transform: "rotate(-2deg)" }}>
                    Much Better Documents</span>
                  <span className="bg-transparent inline">than GPT-3.5</span>
                </div>
              </div>
            </div>
            {page == "dashboard" && <div className="text-center sm:text-right">
              <button className='btn btn-success'>
                {lockOpen && <LockOpenIcon className="w-6 h-6 text-yellow-800" />}
                {!lockOpen && <LockClosedIcon className="w-6 h-6" />} Upgrade </button></div>}
            {page == "landing" &&  <a className="text-center md:text-right w-full md:w-auto lg:mr-48" href="#pricing">
              <button className='btn btn-success'>
                {lockOpen && <LockOpenIcon className="w-6 h-6 text-yellow-800" />}
                {!lockOpen && <LockClosedIcon className="w-6 h-6" />} Purchase </button></a>}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='ml-6 mr-36 mt-4 cursor-pointer'>
            <svg aria-hidden="true" viewBox="0 0 418 42"
              className="right-0 h-[2em] fill-emerald-300/70 -mt-6 transform"
              style={{ transform: "rotate(173deg)" }}
              preserveAspectRatio="none">
              <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z">
              </path>
            </svg>
          </div>
        </div>)}
        <legend className="label-text p-2">Description</legend>
        <textarea
          name="nodeDescription"
          value={formData.nodeDescription}
          onChange={handleChange}
          placeholder="Provide a description for the document you want to write, just need to be longer than 10 characters"
          className={`w-full textarea textarea-bordered input-lg placeholder-gray-600 ${heightCss}`}
        ></textarea>
        {errors.nodeDescription &&
          <span className="text-error p-4 rounded-lg border border-gray-900">
            <ExclamationCircleIcon className="h-16 w-16 mr-4" />
            {errors.nodeDescription}
          </span>}
        <button
          onClick={validateAndSubmit}
          className="btn btn-primary mt-4 rounded-lg mb-8"
          disabled={!isDownloadReady && submitted}
        >
          Let AI Write the Document
        </button>
      </fieldset>
    </div>
  );
};

export default DataInputForm;
