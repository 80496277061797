"use client"

import ButtonCheckout from "./ButtonCheckout";
import { useEffect, useState } from "react";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import config, { isDevelopment } from "@/config";
import { BasicUser } from "@/types/service-invoke";
import LemonSqueezeButtonCheckout from "./LemonSqueezeButtonCheckout";

// <Pricing/> displays the pricing plans for your app
// It's your Stripe config in config.js.stripe.plans[] that will be used to display the plans
// <ButtonCheckout /> renders a button that will redirect the user to Stripe checkout called the /api/stripe/create-checkout API endpoint with the correct priceId

const Pricing = (props: { title?: string, hideFree?: boolean, user?: BasicUser }) => {
  const { title, hideFree, user } = props;
  const [isAnnual, setIsAnnual] = useState(true);
  const supabase = createClientComponentClient();
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      let { data: servicePlans, error } = (
        await supabase
          .from('service_plans')
          .select(`
          *,
          plan_features ( feature_name )
        `)
          .eq('testing', isDevelopment) // only show plans that are for testing or not for testing
          .eq('active', true)
          .eq('tenant', config.tenant)
          .order('display_sequence', { ascending: true })
      );

      console.log(servicePlans, ' <--- data value');
      if (error) {
        console.log("error", error);
      } else {
        setPlans(servicePlans);
      }
    };

    fetchPlans();
  }, [supabase]);

  const calculateAnnualPrice = (monthlyPrice: number) => ((monthlyPrice * 8) / 12).toFixed(2);

  const isAnnualSubscription = (mode: string): boolean => (mode === "subscription" && isAnnual);

  return (
    <section className={`bg-base-200 overflow-hidden py-4`} id="pricing">
      <div className="py-6 px-8 max-w-7xl mx-auto">
        <div className="flex flex-col text-center w-full mb-8">
          <h3 className="font-bold text-3xl lg:text-5xl tracking-tight">
            {(title == null) && <>Price of a cup of 🍵 <br /> Let AI do the work as you enjoy your break</>}
            {title != null && <>{title}</>}
          </h3>
        </div>

        <div className="flex justify-center items-center mb-6 cursor-pointer">
          {/* Toggle container */}
          <div
            className={`relative w-full lg:w-64 h-12 flex items-center rounded-full p-1 transition duration-300 ease-in-out ${isAnnual ? 'justify-end' : 'justify-start'
              }`}
          >
            {/* Text "Monthly" */}
            <div
              className={`absolute z-20 left-2 sm:left-6 ml-1 transition duration-300 ease-in-out text-xl font-bold`}
              onClick={() => setIsAnnual(false)}
            >
              <span className="text-primary">Monthly</span>
            </div>
            {/* Text "Yearly" */}
            <div
              className={`absolute z-20 right-2 sm:right-8 mr-1 transition duration-300 ease-in-out text-xl font-bold`}
              onClick={() => setIsAnnual(true)}
            >
              <span className="text-primary">Yearly</span>
            </div>
            {/* Toggle */}
            <div
              className="w-32 h-10 z-10 bg-white rounded-full shadow-md transform transition duration-300 ease-in-out"
            ></div>
          </div>
        </div>

        <div className="relative flex justify-center flex-col lg:flex-row items-center lg:items-stretch gap-8">
          {plans.map((plan, idx) => {
            if (hideFree && plan.price === 0) return null;
            const userEmail = user?.email ? `&checkout[email]=${user.email}` : '';
            const lemonsqueezeCheckoutUrl = (isAnnualSubscription(plan.mode) ? plan.lemonsqueeze_annual_checkout_url : plan.lemonsqueeze_checkout_url) + userEmail;
            return (
              <div key={"price_section_" + idx} className="relative w-full">
                {plan.is_featured && (
                  <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
                    <span
                      className={`badge text-xs text-primary-content font-semibold border-0 bg-primary`}
                    >
                      POPULAR
                    </span>
                  </div>
                )}

                {plan.is_featured && (
                  <div
                    className={`absolute -inset-[1px] rounded-[9px] bg-primary z-10`}
                  ></div>
                )}

                <div className="relative flex flex-col h-full gap-5 lg:gap-8 z-10 bg-base-100 p-8 rounded-lg">
                  <div className="flex justify-between items-center gap-4">
                    <div>
                      <p className="text-lg lg:text-xl font-bold">{plan.name}</p>
                      {plan.description && (
                        <p className="text-base-content/80 mt-2">
                          {plan.description}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    {plan.price_anchor && (
                      <div className="flex flex-col justify-end mb-[4px] text-lg ">
                        <p className="relative">
                          <span className="absolute bg-base-content h-[1.5px] inset-x-0 top-[53%]"></span>
                          <span className="text-base-content/80">
                            ${(isAnnualSubscription(plan.mode)) ? calculateAnnualPrice(plan.price_anchor) : plan.price_anchor}
                          </span>
                        </p>
                      </div>
                    )}
                    <p className={`text-5xl tracking-tight font-extrabold`}>
                      ${(isAnnualSubscription(plan.mode)) ? calculateAnnualPrice(plan.price) : plan.price}
                    </p>
                    <div className="flex flex-col justify-end mb-[4px]">
                      <p className="text-xs text-base-content/60 uppercase font-semibold">
                        USD {plan.mode === 'subscription' && "/ Monthly"}
                      </p>
                    </div>
                  </div>
                  {plan.plan_features && (
                    <ul className="space-y-2.5 leading-relaxed text-base flex-1">
                      {plan.plan_features.map((feature: { feature_name: string }, i: number) => {
                        return (
                          <li key={i} className="flex items-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-[18px] h-[18px] opacity-80 shrink-0 text-green-500"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                clipRule="evenodd"
                              />
                            </svg>

                            <span>{feature.feature_name} </span>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div className="space-y-2">
                    {config.activePaymentProvider === 'lemonsqueeze' &&
                      <LemonSqueezeButtonCheckout url={lemonsqueezeCheckoutUrl} text={plan.payment_button_label} />
                    }
                    {config.activePaymentProvider === 'stripe' &&
                      <ButtonCheckout priceId={(isAnnualSubscription(plan.mode)) ? plan.yearly_price_id : plan.price_id} mode={plan.mode ?? "subscription"} text={plan.payment_button_label} />}
                    <p className="flex items-center justify-center gap-2 text-sm text-center text-base-content/80 font-medium relative">
                      {plan.below_button_description}
                    </p>
                  </div>
                </div>
              </div>
            );
          }
          )}
          <div id="purchase-buttons" />
        </div>

        {/* Revised Section for Price Increase Notification and Current Rate Advantage */}
        <div className="bg-base-100 text-center p-6 mt-6 rounded-lg shadow-lg">
          <a href="#innovations" className="text-gray-400">
            <h3 className="text-lg lg:text-xl font-semibold mb-2 underline">
              Grab Today&#39;s Price, Enjoy Tomorrow&#39;s Upgrades
            </h3>
            <p className="text-sm lg:text-base">
              <strong>New features are coming</strong> – and with them, higher prices. <br />
              Secure your subscription at today&#39;s rate now <br />
              enjoy all the upcoming enhancements for <strong>one full year without any extra cost</strong>.
            </p>
          </a>
        </div>
      </div>
    </section >
  );
};

export default Pricing;
